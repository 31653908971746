<template>
 <div class="vg_wrapper" v-loading="loadingFlag">
   <el-card>
    <div class="vd_button_group">
      <span v-if="disabled === true">
        <el-button type="success" @click="openEdit" size="small" :disabled="!btn.edit">编辑</el-button>
        <el-button @click="drawer = true" v-if="specialDataFlag" size="small" type="warning" class="vd_export1">分享账套</el-button>
      </span>
      <span v-if="disabled === false">
         <el-button type="primary" @click="submit('roleForm')" size="small">保存</el-button>
         <el-button  @click="closeEdit" size="small">取消</el-button>
      </span> 
   </div>
    <el-form ref="roleForm" :model="roleForm" :rules="rules" :disabled="disabled" label-width="120px" size="mini">
     <el-row>
       <el-col :md="8">
         <el-form-item label="角色名称" prop="role_name">
           <el-input v-model="roleForm.role_name" @input="roleForm.role_name=helper.keepSpace(roleForm.role_name)" maxlength="10" show-word-limit></el-input>
         </el-form-item>
       </el-col>
       <el-col :md="8">
         <el-form-item>
          <el-switch
            :disabled="switchFlag"
            style="display: block"
            v-model="roleForm.role_status"
            @change="switchChange"
            :active-value='1'
            :inactive-value='0'
            active-text="启用"
            inactive-text="关闭">
          </el-switch>
         </el-form-item>
       </el-col>
     </el-row>
    <div class="vd_button"></div>
    <el-row class="vg_mb_8">
     <el-col :md="12">
       <el-input placeholder="输入关键字进行过滤" v-model="filterText" size="mini"></el-input>
     </el-col>
   </el-row>
    <el-row>
     <el-col :md="12">
       <el-tree
           :data="roleForm.role_perm_id_list"
           ref="tree"
           show-checkbox
           node-key="perm_id"
           :props="roleForm.defaultProps"
           :filter-node-method="filterNode"
           @check-change="getCheckedData"
           @check="getNodeIds"
           v-if="treeDeath"
       >
       </el-tree>
     </el-col>
   </el-row>
   <!-- <el-row :gutter="24" class="vd_rang ">
        <el-col class="vd_elform" :md="10">
          <el-form-item  label="分享账套">
            <el-button class="vd_mrf20" type="primary" plain size="mini" @click="addType()">添加</el-button>
          </el-form-item>
          <el-table :data="roleForm.role_acct_list" border>
            <el-table-column label="账套名称">
              <template slot-scope="scope">
                <el-form-item label-width="0" :prop="'role_acct_list.'+scope.$index+'.acct_id'" :rules="[{required:true}]">
                  <el-select @change="acctSelect(scope)" v-model="roleForm.role_acct_list[scope.$index].acct_id" :disabled="scope.row.role_acct_status === 1" placeholder="请选择账套">
                    <el-option
                      v-for="item in acctCopy"
                      :key="item.acct_id"
                      :label="item.acct_no"
                      :value="item.acct_id"
                      :disabled="item.disabled">
                    </el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column align="center" width="150px">
              <template slot-scope="scope">
                <el-link type="danger" class="vg_cursor" :disabled="scope.row.role_acct_status === 1 || disabled" @click="delType(scope)">移除</el-link>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row> -->
   </el-form>
   </el-card>
   <el-drawer :visible.sync="drawer" :direction="direction" :before-close="handleCloseDrawer">
      <div class="vd_draw">
        <el-card>
          <el-button type="success" class="vg_mb_16" size="small" v-if="!acctPreseFlag" @click="acctPreseFlag = true">编辑</el-button>
          <el-button type="primary" class="vg_mb_16" size="small" v-if="acctPreseFlag"  @click="preseType()">保存</el-button>
          <el-button class="vg_mb_16" size="small" v-if="acctPreseFlag"  @click="cancelType()">取消</el-button>
          <el-button type="primary" size="small" v-if="acctPreseFlag" plain @click="addType()">添加</el-button>
          <el-table :data="roleForm.role_acct_list" border>
            <el-table-column label="账套名称">
              <template slot-scope="scope">
                <el-select @change="acctSelect(scope)" size="small" v-model="roleForm.role_acct_list[scope.$index].acct_id" :disabled="scope.row.role_acct_status === 1 || !acctPreseFlag" placeholder="请选择账套">
                  <el-option
                    v-for="item in acctCopy"
                    :key="item.acct_id"
                    :label="item.acct_no"
                    :value="item.acct_id"
                    :disabled="item.disabled">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column align="center" width="150px">
              <template slot-scope="scope">
                <el-link type="danger" class="vg_cursor" :disabled="scope.row.role_acct_status === 1 || !acctPreseFlag" @click="delType(scope)">移除</el-link>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </el-drawer>
 </div>
</template>

<script>
import {get,post} from "@api/request";
import {roleAPI} from "@api/modules/role"
import {acctAPI} from "@api/modules/acct";
import {permAPI} from "@api/modules/perm"
export default {
  name: "RoleEdit",
  data(){
    return{
      disabled: true,
      roleForm:{
        role_id:null,
        role_perm_id_list:[],
        defaultProps: {
          children: 'perm_child_list',
          label: (data)=>data.perm_id+' '+data.perm_name,
          disabled: this.disabledFn
        }
      },
      checkedTreeArr: [],
      actionsIds:[],
      actionsIdsGroup:[],
      permIdArr:[],
      rules:{
        role_name:[{ required: true, trigger: 'blur' ,message:' '}]
      },
      filterText:'',
      btn:{},
      treeDeath: false,
      userList:[],
      role_name:'',
      loadingFlag:true,
      acctFlag:false,
      switchFlag:false,
      loadFlag:true,
      drawer: false,
      direction: 'rtl',
      acctCopy:[],
      roleAcctCope:[],
      specialDataFlag:false,
      acctPreseFlag:false
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  created() {
    this.initData()
  },
  methods:{
    //启用编辑
    openEdit(){
      this.disabled = false
      this.disabledFn()
    },
    // tree 禁止
    disabledFn(){
      if(this.roleForm.role_status === 1){
        return true
      } else {
        if(!this.disabled){
          return false
        }else{
          return true
        }
      }
    },
    //取消编辑
    closeEdit(){
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.disabled = true
        this.initData()
        this.$message({
          type: 'info',
          message: '已撤销编辑!'
        });
      }).catch(()=>{})
     },
    initData(){
      this.loadingFlag = true
      this.treeDeath = true
      this.getRoleInfo()
      this.disabledFn()
      this.getRoles()
      this.getAccts()
      setTimeout(() => {
        this.getPermTree();
        if(this.$cookies.get('userInfo').acct_id === 1){
          this.specialDataFlag = true
        }
      }, 100);
    },
    // 获取账套accts
    getAccts(){
      get(acctAPI.getAllAcctsV1)
      .then(res =>{
        if(res.data.code === 0){
          this.acctCopy = res.data.data;
          for(let i=0;i<this.acctCopy.length;i++){
            if(this.$cookies.get('userInfo').acct_id === this.acctCopy[i].acct_id){
              this.acctCopy[i].disabled  = true
            }
          }
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
      })
      .catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message:mg,type:tp});
      })
    },
    // 获取角色信息
    getRoles() {
      get(roleAPI.getAllRoles)
          .then(res => {
            if(res.data.code === 0){
              for(let i of res.data.data.list){
                this.userList.push(i.role_name)
              }
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 获取角色信息
    getRoleInfo(){
      this.roleForm.role_id = this.$route.query.form_id
      get(roleAPI.getRoleById,{role_id:this.roleForm.role_id})
      .then(res=>{
        if(res.data.code === 0){
          this.roleForm = res.data.data.form
          this.role_name = JSON.parse(JSON.stringify(res.data.data.form.role_name))
          this.btn = res.data.data.btn
          this.roleAcctCope = JSON.parse(JSON.stringify(this.roleForm.role_acct_list))
          this.acctFlag = JSON.parse(JSON.stringify(this.roleForm.role_status))
          // for(let i=0;i<this.roleForm.role_acct_list.length;i++){
          //   if(this.roleForm.role_status){
          //     this.roleForm.role_acct_list[i].disabled = true
          //   }else{
          //     this.roleForm.role_acct_list[i].disabled = false
          //   }
          // }
          if(this.roleForm.role_perm_ids!==null){
            this.permIdArr = []
            let permIdStr = this.roleForm.role_perm_ids.split(",")
            permIdStr.forEach(item => {
              this.permIdArr.push(+item)
            })
            this.checkedTreeArr = JSON.parse(JSON.stringify(this.permIdArr))
            this.$refs.tree.setCheckedKeys(this.permIdArr,true,true)
          }
          setTimeout(() => {
            this.loadingFlag = false
          }, 500);
        }else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        }
      })
      .catch(res => {
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    // 获取权限树状 
    getPermTree(){
      get(permAPI.getAllPerms)
      .then(res=>{
        if(res.data.code === 0){
          this.roleForm.role_perm_id_list = res.data.data;
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
      }).catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    // 树节点过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.perm_name.indexOf(value) !== -1;
    },
    // 获取角色权限数据
    getNodeIds(val,checkedArr){ 
      checkedArr.checkedNodes.map((item)=>{
        this.checkedTreeArr.push(item.perm_id)
      })
      checkedArr.halfCheckedNodes.map((item)=>{
        this.checkedTreeArr.push(item.perm_id) 
      })
      this.checkedTreeArr = checkedArr.checkedKeys.concat(checkedArr.halfCheckedKeys)
    },
    getCheckedData(val){
      this.actionsIds = [];
      this.actionsIdsGroup = this.$refs.tree.getCheckedNodes(false,true)
      this.actionsIdsGroup.map((item)=>{
        this.actionsIds.push(item.perm_id)
      })
    },
    //启用按钮叼接口
    switchChange(){
      this.switchFlag = true;
      post(roleAPI.editRoleStatus,{role_id:this.roleForm.role_id,role_status:this.roleForm.role_status})
      .then((res)=>{
        if(res.data.code === 0){
          for(let i=0;i<this.roleForm.role_acct_list.length;i++){
              if(this.roleForm.role_status === 1){
                if(this.roleForm.role_acct_list[i].role_acct_id){
                  this.roleForm.role_acct_list[i].disabled = true
                }else{
                  this.roleForm.role_acct_list[i].disabled = false
                }
              }else{
                this.roleForm.role_acct_list[i].disabled = false
            }  
          }
          setTimeout(() => {
            this.switchFlag = false
          }, 100);
        }else if(res.data.code === 999){
          this.$message.error(res.data.msg)
          if(this.roleForm.role_status === 1){
            this.roleForm.role_status = 0
          }else{
            this.roleForm.role_status = 1
          }
        }
      })
      .catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message:mg,type:tp});
      })
    },
    // 选择账套重复判断
    acctSelect(val){
      if(val.row.acct_id !== null){
        for(let i=0;i<this.roleForm.role_acct_list.length;i++){
          if(i !== val.$index){
            if(this.roleForm.role_acct_list[i].acct_id === val.row.acct_id){
              val.row.acct_id = null;
              this.$message({
                type:'warning',
                message:'账套选择重复'
              })
              return ;
            }
          }
        }
      }
    },
    // 特殊数据范围添加
    addType() {
      let item = {
        acct_id:null,
        disabled: false
      };
      this.roleForm.role_acct_list.push(item)
    },
    // 特殊数据范围添加删除
    delType(scope){
      if(scope.row.disabled){
        this.$message.error("该账套不能删除")
      }else{
        this.roleForm.role_acct_list.splice(scope.$index,1)
      }
    },
    // 关闭特殊数据范围
    handleCloseDrawer(){
      let flag = false;
      for(let i=0;i<this.roleForm.role_acct_list.length;i++){
        if(this.roleForm.role_acct_list[i].acct_id === null){
          flag = true;
        }
      }
      if(flag){
        this.$message.warning('请把分享的账套补充完整')
      }else{
        if(this.acctPreseFlag){
          this.$message.warning('请先保存或取消分享账套的编辑')
        }else{
          this.drawer = false
          // this.$confirm('确认关闭？')
          // .then(_ => {
          //   this.drawer = false
          // })
          // .catch(_ => {});
        }
      }
    },
    // 保存分享账套
    preseType(){
      let temp = false
      for(let i=0;i<this.roleForm.role_acct_list.length;i++){
        if(this.roleForm.role_acct_list[i].acct_id === null){
          temp = true
        }
      }
      if(temp){
        this.$message.warning("请把账套名称补充完整")
      }else{
        this.$confirm("确认保存?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then((res)=>{
          let acctList = JSON.parse(JSON.stringify(this.roleForm.role_acct_list))
          this.roleAcctCope = this.roleAcctCope.filter(item=>acctList.every(item1=>item.role_acct_id !== item1.role_acct_id))
          for(let i=0;i<this.roleAcctCope.length;i++){
            this.roleAcctCope[i].destroy_flag = 1
          }
          acctList = acctList.concat(this.roleAcctCope)
          if(acctList.length>0){
            post(roleAPI.roleShareAccts,{role_id:this.roleForm.role_id,role_acct_list:acctList})
            .then(res=>{
              if(res.data.code === 0){
                this.$message.success("保存成功")
                // this.drawer = false
                this.acctPreseFlag = false
                this.initData()
              }else{
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({message:mg,type:tp});
              }
            })
            .catch(res=>{
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            })
          }else{
            this.$message.success("保存成功")
            this.acctPreseFlag = false
            this.initData()
          }
        })
        .catch(()=>{})
      }
    },
    // 取消分享账套
    cancelType(){
      this.$confirm("确认取消?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then((res)=>{
        this.acctPreseFlag = false
        this.initData()
      })
      .catch(()=>{})
    },
    //提交表单
    submit(formName){
      // let f = 0;
      // for(let i of this.userList){
      //   if(this.roleForm.role_name === i){
      //       f++
      //   }
      // }
      // if(f<=1){
        this.$confirm('确定提交？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              this.saveInfo()
            } else {
              return false;
            }
          });
        }).catch(()=>{ })
      // }else {
      // return  this.$message.warning('该角色名称已存在')
      // }
    },
    // 保存
    saveInfo(){
      let roleBody = {}
      roleBody.role_id = this.roleForm.role_id
      roleBody.role_name = this.roleForm.role_name
      roleBody.role_acct_list = JSON.parse(JSON.stringify(this.roleForm.role_acct_list))
      roleBody.role_status = this.roleForm.role_status
      if(this.checkedTreeArr.length ===0){
        roleBody.role_perm_id_list = this.actionsIds
      } else {
        roleBody.role_perm_id_list = this.checkedTreeArr
      }
      roleBody.role_perm_id_list =  [...new Set(roleBody.role_perm_id_list)]
      this.roleAcctCope = this.roleAcctCope.filter(item=>this.roleForm.role_acct_list.every(item1=>item.role_acct_id !== item1.role_acct_id))
      for(let i=0;i<this.roleAcctCope.length;i++){
        this.roleAcctCope[i].destroy_flag = 1
      }
      roleBody.role_acct_list = roleBody.role_acct_list.concat(this.roleAcctCope)
      if(roleBody.role_perm_id_list.length>0){
        post(roleAPI.editRole,roleBody)
        .then(res=>{
          if(res.data.code === 0){
            this.$message({
              type:'success',
              message:'保存成功！'
            })
            this.disabled = true
            this.initData()
          } else {
            this.$message({
              type:'error',
              message:res.data.msg
            })
          }
        })
        .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
      }else{
        this.$message({
          type:'error',
          message:"至少选择一条"
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.vd_button{
  margin-top: 32px;
  border-top: 1px dashed $color-secondary-text;
  padding-top: 16px;
}
.vd_button_group {
  padding-bottom: 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid $color-secondary-text;
}
.vd_title{
  color: $color-deep-red;
}
.vd_rang{
  margin-top: 20px;
  border-top: 1px dashed #C0C4CC;
  padding-top: 10px;
}
.vd_elform{
  display: flex;
}
.vd_mrf20{
margin-right: 20px;
} 
</style>