import topic from '../topic'
export const roleAPI = {
    getAllRolesV1: topic.roleTopic + '/get_all_roles_v1',
    getAllRoles: topic.roleTopic + '/get_all_roles',
    getRoleById: topic.roleTopic + '/get_role_by_id',
    addRole: topic.roleTopic + '/add_role',
    editRole: topic.roleTopic + '/edit_role',
    destroyRoleByIds: topic.roleTopic + '/destroy_role_by_ids',
    editRoleStatus: topic.roleTopic + '/edit_role_status',
    roleShareAccts: topic.roleTopic + '/role_share_accts',
}